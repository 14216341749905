<template>
  <div class="app-main-content">
    <div class="header">
      <div class="title">
        <div>项目计划</div>
        <h2>全部里程碑</h2>
        <div>共{{ issue_totalcount }}条</div>
      </div>
      <div class="btns">
        <a-space>
          <a-button icon="plus" @click="openModal">
            里程碑
          </a-button>
        </a-space>
      </div>
    </div>
    <div class="steps">
      <project-milestone-steps ref="prjmsteps" :prj-oid="prj_oid" :getChildList="getChildList">
        <template slot="title">里程碑时间轴{{ complete_percent }}%</template>
      </project-milestone-steps>
    </div>
    <div class="table">
      <a-table :data-source="list" :pagination="false" rowKey="oid">
        <a-table-column title="ID" data-index="seqid"  width="70px"/>
        <a-table-column title="里程碑名称" width="500px">
          <template slot-scope="scope">
            <router-link style="color: #08f;" :to="{path: '/project/plan/milestoneDetails', query: {oid: scope.oid, prj_oid: prj_oid}}">{{scope.issue_name}}</router-link>
          </template>
        </a-table-column>
<!--        <a-table-column title="状态" width="110px">-->
<!--          <template slot-scope="row">-->
<!--            <edp-select-->
<!--                :skin="`status ${row.issue_status === '1' ? 'yellow' : row.issue_status === '100' ? 'green' : ''}`"-->
<!--                v-model="row.issue_status"-->
<!--                :db="dbs.prjIssue"-->
<!--                field-name="issue_status"-->
<!--                :oid="row.oid"-->
<!--                :prj-oid="row.prjoid"-->
<!--                cat-type="issue_bstatus"-->
<!--            ></edp-select>-->
<!--          </template>-->
<!--        </a-table-column>-->
            <a-table-column title="进度" width="150px">
              <template slot-scope="row">
                <a-progress strokeColor="#24B47E" :percent="row.complete_percent - 0" />
              </template>
            </a-table-column>
        <a-table-column title="截止日期" data-index="schedule_end_date" width="120px"/>
<!--        <a-table-column title="开始日期" data-index="schedule_start_date" width="200px" align="center"/>-->
        <a-table-column title="交付物">
          <template #default="_, row">
            <span>
              已提交：{{row.running_deliverycount || 0}}{{row.total_deliverycount ? `/${row.total_deliverycount}` : ''}}
            </span>
            <span>
              已审批：{{row.complete_deliverycount || 0}}{{row.total_deliverycount ? `/${row.total_deliverycount}` : ''}}
            </span>
          </template>
        </a-table-column>
      </a-table>
    </div>
    <create-milestone-modal ref="modal" :prj-oid="prj_oid" @reload="refreshList"></create-milestone-modal>
  </div>
</template>

<script>
import projectMilestoneSteps from "@/components/projectMilestoneSteps";
import createMilestoneModal from "@/components/createMilestoneModal";
import edpSelect from "@/components/e-form/edp-select.vue";
import {fetch} from "@/utils/request";
import {genGuid} from "@/utils/helpFunc";
import {FieldUpdateDb} from "@/utils/FieldUpdate";
export default {
  name: "projectPlanMilestone",
  data() {
    return{
      dbs: {
        prjIssue: new FieldUpdateDb("prj", "prj_issue")
      },
      list: [],
      prj_oid: '',
      issue_totalcount: '',
      complete_percent: ''
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {projectMilestoneSteps, createMilestoneModal, edpSelect},
  created() {
    this.prj_oid = this.$route.query.prj_oid
  },
  mounted() {
    if (this.prj_oid) {
      this.getProMilepostSta() // 获取里程碑总数和进度
    }
  },
  methods: {
    // 获取里程碑总数和进度
    getProMilepostSta() {
      fetch('post', '/prj/issue/milepost/sta', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let {complete_percent, issue_totalcount} = res.data
          this.issue_totalcount = issue_totalcount //里程碑总数
          this.complete_percent = complete_percent //里程碑完成进度
        }
      })
    },
    // 从里程碑组件获取里程碑列表数据
    getChildList(data) {
      // console.log(data);
      this.list = data
    },
    // 新增里程碑
    openModal() {
      this.$refs.modal.open({
        oid: genGuid()
      })
    },
    // 新增里程碑后刷新列表
    refreshList() {
      this.$refs.prjmsteps.getMilepostList()
    }
  }
}
</script>

<style scoped lang="less">
.app-main-content{
  padding: 0 7px;
  //height: calc(100% - 50px);
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);

  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    background: #FFFFFF;
    border-radius: 4px 4px 0 0;

    .title{
      font-size: 12px;
      color: #494949;

      h2{
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .steps{
    background: #FFF;
    padding: 12px 21px 12px;
    border-top: 1px solid #CACACA;
  }

  .table{
    background: #FFF;
    height: calc(100% - 331px);
    overflow-y: auto;
  }
}
</style>
